import axios from 'axios';
import * as Sentry from '@sentry/vue';
import getCookie from '../utils/cookie.js';
import { useLoaderStore } from '../store/loader.js';

const baseURL = process.env.VUE_APP_API_BASE_URL;
const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const loaderStore = useLoaderStore();

    if (!loaderStore.ignoreLoader) {
      loaderStore.showLoader();
    }
    const token = localStorage.getItem('token') ?? getCookie('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    const loaderStore = useLoaderStore();
    loaderStore.hideLoader();
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    const loaderStore = useLoaderStore();
    loaderStore.hideLoader();
    return response;
  },
  (error) => {
    const loaderStore = useLoaderStore();
    loaderStore.hideLoader();
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      document.cookie = `token=;path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};expires=Thu, 01 Jan 1970 00:00:01 GMT"`;
    } else {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  },
);

export const login = async (credentials) => {
  try {
    const response = await api.post('/api/v2/login', credentials);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const loginProfExpress = async (credentials) => {
  try {
    const response = await api.post('/api/v2/profexpress-login', credentials);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
export const register = async (userData) => {
  try {
    const response = await api.post('/api/v2/register', userData);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const updateProfil = async (userData) => {
  try {
    const response = await api.put('/api/v2/user/profile', userData);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const updatePassword = async (payload) => {
  try {
    const response = await api.put('/api/v2/user/password', payload);
    return response;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const resetPassword = async (body) => {
  try {
    const response = await api.post('api/v2/reset-password', body);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await api.post('/api/v2/forgot-password', email);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const neededUpdatePassword = async (payload) => {
  try {
    const response = await api.post('/api/v2/update-password', payload);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
export const deleteUser = async () => {
  try {
    const response = await api.put('/api/v2/user/delete');
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const getServiceCategories = async () => {
  try {
    const response = await api.get('/api/v2/company/categories');
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const getUserServices = async () => {
  const loaderStore = useLoaderStore();
  loaderStore.enableIgnoreLoader();
  try {
    const response = await api.get('/api/v2/user/services');
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  } finally {
    loaderStore.disableIgnoreLoader();
  }
};

export const sendRequestServiceTypeForm = async (serviceId, requestBody) => {
  let response;
  try {
    response = await api.post(`/api/v2/service/${serviceId}/request`, requestBody);
  } catch (error) {
    Sentry.captureException(error);
  }
  return response;
};

export const RequestServiceAccess = async (serviceId) => {
  let response;
  try {
    response = await api.post(`/api/v2/service/${serviceId}/request-access`);
  } catch (error) {
    Sentry.captureException(error);
  }
  return response;
};

export const sendRequestServiceTypeCode = async (serviceId) => {
  let response;
  try {
    response = await api.post(`/api/v2/service/${serviceId}/request-code`);
  } catch (error) {
    Sentry.captureException(error);
  }
  return response;
};

export const getUrlServiceTypeLink = async (serviceId) => {
  let response;
  try {
    response = await api.get(`/api/v2/service/${serviceId}/url`);
  } catch (error) {
    Sentry.captureException(error);
  }
  return response;
};

export const sendSmsCodeForServiceAccess = async (serviceId) => {
  try {
    const response = await api.get(`/api/v2/service/${serviceId}/send-sms`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const verifySmsCodeForServiceAccess = async (serviceId, payload) => {
  try {
    const response = await api.post(`/api/v2/service/${serviceId}/verification-sms`, payload);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const sendSmsCodeForPhoneVerification = async () => {
  try {
    const response = await api.get('/api/v2/user/send-sms');
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const verifySmsCodeForPhoneVerification = async (payload) => {
  try {
    const response = await api.post('/api/v2/user/verification-sms', payload);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const validateAndAssociateEmployeeNumber = async (payload) => {
  let response;
  try {
    response = await api.post('/api/v2/user/validate-and-associate-employee', payload);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
  return response;
};

export const updateSkipModalPreference = async (serviceId) => {
  let response;
  try {
    response = await api.put(`/api/v2/service/${serviceId}/update-preference`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
  return response;
};

export const submitMessageToSupport = async (message) => {
  try {
    const response = await api.post('api/v2/support/inquiry', message);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const getUserInformation = async () => {
  const loaderStore = useLoaderStore();
  loaderStore.enableIgnoreLoader();
  try {
    const response = await api.get('/api/v2/user/infos');
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  } finally {
    loaderStore.disableIgnoreLoader();
  }
};

export const getCompany = async (params) => {
  try {
    const response = await api.get('/api/v2/company', { params });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const fetchActiveSurvey = async () => {
  try {
    const response = await api.get('/api/v2/survey/active');
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const checkSurveyAnswered = async (surveyId) => {
  try {
    const response = await api.get(`/api/v2/survey/${surveyId}/answered`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const submitSurveyResponse = async (responseBody) => {
  try {
    const response = await api.post('/api/v2/survey/submit-response', responseBody);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
