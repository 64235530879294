<template>
  <teleport to="body">
    <div
      class="modal-overlay"
    >
      <div class="modal-content-container">
        <div class="modal-title">
          Vérification de l'accès via SMS
        </div>
        <div class="modal-content">
          <p>Un sms a été envoyé au : <br> {{ formattedPhone }}</p>
          <input
            v-model="userInputCode"
            type="text"
            placeholder="Entrez le code SMS"
          >
          <p
            v-if="showErrorMessage"
            class="error-message"
          >
            Trop de mauvaises tentatives, un nouveau code vient de vous être renvoyé sur {{ formattedPhone }}
          </p>
        </div>
        <div class="modal-actions">
          <button
            class="button-home-redirection p-1"
            @click="verifyCode"
          >
            Vérifier
          </button>
        </div>
        <br>
        <button
          class="button-ghost text-only"
          @click="resendCode"
        >
          Renvoyer le code de vérification par SMS
        </button>
      </div>
    </div>
  </teleport>
</template>

<script>
import { useToast } from 'vue-toast-notification';
import { getUserInformation, verifySmsCodeForServiceAccess } from '../../../services/api.js';
import { useAuthStore } from '../../../store/store.js';

export default {
  props: {
    serviceId: {
      type: Number,
      required: true,
    },
  },
  emits: ['verification-sms-success', 'close-verification-service-modal', 'resend-code', 'too-many-attempts'],
  data() {
    return {
      userInputCode: '',
      authStore: useAuthStore(),
      attempts: 0,
      showSuccessMessage: false,
      showErrorMessage: false,
    };
  },
  computed: {
    formattedPhone() {
      const { phone } = this.authStore.user;
      if (!phone || phone.length !== 10) return phone;
      return `+33 ${phone.slice(1, 2)} XX XX XX ${phone.slice(-2)}`;
    },
  },
  methods: {
    async verifyCode() {
      try {
        const payload = { code: this.userInputCode };
        const response = await verifySmsCodeForServiceAccess(this.serviceId, payload);

        if (response.success) {
          if (!this.authStore.user.phone_verified_at) {
            const user = (await getUserInformation()).data;
            this.authStore.setUserPhoneVerifiedAt(user.phone_verified_at);
          }
          this.$emit('verification-sms-success');
          this.closeModal();
        } else {
          this.handleFailedAttempt();
        }
      } catch (error) {
        this.handleFailedAttempt();
      }
    },
    handleFailedAttempt() {
      this.attempts += 1;
      if (this.attempts >= 5) {
        this.resendCode();
        this.showErrorMessage = true;
        this.$emit('too-many-attempts');
      } else {
        useToast().error('Code de vérification erroné');
      }
    },
    async resendCode() {
      this.$emit('resend-code');
    },
    closeModal() {
      this.$emit('close-verification-service-modal');
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-top: 5px;
}
.button-ghost {
  background: none;
  border: none;
  color: #052f5f;
  cursor: pointer;
  margin-top: 10px;
}
</style>
